export enum EventTypes {
  ArticleOpened = 'consumeradvise.articleopened:v2',
  ArticleFiftyPercent = 'consumeradvise.articlefiftypercent:v2',
  ArticleCompleted = 'consumeradvise.articlecompleted:v2',
  VideoOpened = 'consumeradvise.videoopened:v2',
  VideoStarted = 'consumeradvise.videostarted:v2',
  VideoFiftyPercent = 'consumeradvise.videofiftypercent:v2',
  VideoCompleted = 'consumeradvise.videocompleted:v2',
  VerificationLaunch = 'consumeradvise.verificationlaunch:v2',
  DashboardLaunch = 'consumeradvise.inboxlaunch:v2',
  DashboardTitleClicked = 'consumeradvise.inboxtitleclicked:v2',
}

export enum EventSource {
  Healthwise = 'Healthwise',
  InHouse = 'In-House',
  CDC = 'CDC',
  FDB = 'FDB',
  NCI = 'NCI',
  CAM = 'CAM',
  NA = 'N/A',
  Unknown = 'unknown',
}

export enum EventOrigin {
  QRCode = 1,
  PatientPortal = 2,
}

export enum EventContentFormat {
  Text = 1,
  Video = 2,
  PDF = 3,
}

export enum EventVerification {
  Success = 1,
  Skip = 2,
  Portal = 3,
}

export const eventSourceValues = {
  [EventSource.Healthwise as string]: 'healthwise',
  [EventSource.InHouse as string]: 'in-house',
  [EventSource.CDC as string]: 'cdc',
  [EventSource.FDB as string]: 'fdb',
  [EventSource.NCI as string]: 'nci',
  [EventSource.CAM as string]: 'cam',
  [EventSource.NA as string]: 'n/a',
  [EventSource.Unknown as string]: 'unknown',
}

export const eventContentFormatValues = {
  [EventContentFormat.Text]: 'text',
  [EventContentFormat.Video]: 'video',
  [EventContentFormat.PDF]: 'pdf',
}

export const eventContentFormatHiveValues = {
  [EventContentFormat.Text]: 'Article' as __EventFormat,
  [EventContentFormat.Video]: 'Video' as __EventFormat,
  [EventContentFormat.PDF]: 'PDF' as __EventFormat,
}

export const eventVerificationValues = {
  [EventVerification.Success]: 'success',
  [EventVerification.Skip]: 'skip',
  [EventVerification.Portal]: 'portal',
}
