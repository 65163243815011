/**
 * This is temporary.
 * When we have more than two languages
 * this component will need to be replaced
 */

import { FunctionComponent, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './LanguageEnSpToggle.module.scss'
import { useLocalize, LocalizeForm } from 'localize'

interface Props {
  className?: string
  onLanguageSelect?: (lang: string) => void
  localization?: string
  ariaDescribedBy?: string
  labelPrefix?: string
}

const getOppositeLocalization = (loc: string) => {
  return loc === 'en-us' ? 'es-us' : 'en-us'
}

const LanguageEnSpToggle: FunctionComponent<Props> = ({
  className,
  onLanguageSelect,
  localization,
  ariaDescribedBy,
  labelPrefix,
}) => {
  const { getLocalizationName, Localize } = useLocalize()
  const [oppositeLocalization, setOppositeLocalization] = useState<string>(
    getOppositeLocalization(localization as string),
  )
  const [alertText, setAlertText] = useState<string>('')

  useEffect(() => {
    setOppositeLocalization(getOppositeLocalization(localization as string))
  }, [localization])

  useEffect(() => {
    setAlertText(
      `${getLocalizationName(localization as string)?.nativeName} ${Localize(
        'Select',
        LocalizeForm.past,
      )}`,
    )
    setTimeout(() => {
      setAlertText('')
    }, 500)
  }, [oppositeLocalization])

  if (localization) {
    return (
      <>
        <button
          data-testid='hw-language-toggle-button'
          className={classNames(styles.languageToggle, className)}
          onClick={() => {
            onLanguageSelect?.(oppositeLocalization)
          }}
          aria-describedby={ariaDescribedBy}
          lang={localization === 'en-us' ? 'es' : 'en'}
        >
          {labelPrefix ? <span className='offscreen-text'>{`${labelPrefix} `}</span> : null}
          <span>
            {getLocalizationName(oppositeLocalization)?.nativeName || oppositeLocalization}
          </span>
        </button>
        {alertText ? (
          <div className='offscreen-text' role='alert'>
            {alertText}
          </div>
        ) : null}
      </>
    )
  }
  return <></>
}

export default LanguageEnSpToggle
