import { FunctionComponent } from 'react'
import styles from './AreaPlaceholder.module.scss'
import { useLocalize, LocalizeForm } from 'localize'
import classNames from 'classnames'

interface Props {
  className?: string
  disableAnimation?: boolean
}

const AreaPlaceholder: FunctionComponent<Props> = ({ className, disableAnimation = false }) => {
  const { Localize } = useLocalize()


  return (
    <div
      title={Localize('Load', LocalizeForm.present)}
      role='alert'
      className={classNames(className, styles.app, { [styles.animate]: !disableAnimation })}
    >
      <span aria-hidden>{''}</span>
      <span className={styles.highContrastLoading}>{Localize('Load', LocalizeForm.present)} …</span>
    </div>
  )
}

export default AreaPlaceholder
