import { useCallback } from 'react'
import { debugErrorLogger, debugLogger, getNewGuid } from 'common'
import {
  eventSettings,
  getLoginLinkFromEventData,
  sanitizeEventData,
} from './_service.backEndEvents'
import { useAppContext } from 'context/useAppContext'
import { HiveEventPaths, postHiveEvent } from 'apis/events.api'

const useEvents = () => {
  const { orgId, subId, assignmentId, assignmentArtifact, loginRedirect } = useAppContext()

  const getStoredHiveContentEventData = (): __IContentEvent => {
    const storedEventData = eventSettings.get('eventContentData')
    if (storedEventData && storedEventData.startsWith('{') && storedEventData.endsWith('}')) {
      return JSON.parse(storedEventData)
    }
    return { eventAction: 'Start' }
  }

  const setSessionId = (newSessionId?: string | null | undefined): string => {
    let sessionIdToUse = newSessionId
    if (!sessionIdToUse) {
      sessionIdToUse = getNewGuid()
    }
    eventSettings.set('sessionId', sessionIdToUse)
    return sessionIdToUse
  }

  const setEventData = (newEventData: __IContentEvent = { eventAction: 'Start' }) => {
    eventSettings.set('eventContentData', JSON.stringify(sanitizeEventData(newEventData)))
  }

  const updateEventData = (partialEventData: __IContentEvent) => {
    if (partialEventData) {
      const storedEventData = getStoredHiveContentEventData()
      const eventDataToUpdate = Object.assign({}, storedEventData, partialEventData)
      setEventData(eventDataToUpdate)
    }
  }

  const addNewEventDataOnly = (partialEventData: __IContentEvent) => {
    if (partialEventData) {
      const storedEventData = getStoredHiveContentEventData()
      const eventDataToAddIfUndefined = Object.assign({}, partialEventData, storedEventData)
      setEventData(eventDataToAddIfUndefined)
    }
  }

  const triggerHiveEvent = (
    event: __IHiveEvent,
    eventPath: HiveEventPaths,
    successCallback: (
      value: string | number | object | boolean,
      data?: object,
    ) => void = debugLogger,
    failureCallback: (value: any) => void = debugErrorLogger,
  ): void => {
    const sessionIdToUse = setSessionId(eventSettings.get('sessionId'))
    event.sessionId = sessionIdToUse

    postHiveEvent(event, eventPath, successCallback, failureCallback)
  }

  const currentEventVersions = {
    launchApplication: 1,
    search: 1,
    ratings: 1,
    content: 1,
  }

  const getLoginLink = useCallback(
    (eventData: __IContentEvent = getStoredHiveContentEventData()) => {
      eventData.organizationId = orgId
      eventData.subscriptionId = subId

      const loginRedirectSplit = loginRedirect?.split('/')
      const assignmentIdFromLoginRedirect = loginRedirectSplit ? loginRedirectSplit[4] || '' : ''
      const assignmentArtifactFromLoginRedirect = loginRedirectSplit
        ? loginRedirectSplit[5] || ''
        : ''

      return getLoginLinkFromEventData(
        eventData,
        false,
        assignmentId || assignmentIdFromLoginRedirect,
        assignmentArtifact || assignmentArtifactFromLoginRedirect,
      )
    },
    [orgId, subId, assignmentId, assignmentArtifact, loginRedirect],
  )

  const getContentLink = useCallback(
    (eventData: __IContentEvent = getStoredHiveContentEventData()) => {
      eventData.organizationId = orgId
      eventData.subscriptionId = subId
      return getLoginLinkFromEventData(eventData, true, assignmentId, assignmentArtifact)
    },
    [orgId, subId, assignmentId, assignmentArtifact],
  )

  return {
    currentEventVersions: currentEventVersions,
    eventData: getStoredHiveContentEventData(),
    updateEventData,
    addNewEventDataOnly,
    triggerHiveEvent,
    getLoginLink,
    getContentLink,
  }
}

export type UseEventType = {
  eventVersions: __EVENT_VERSIONS
  eventData: __IHiveEvent
  updateEventData: (partialEvent: __IHiveEvent) => void
  addNewEventDataOnly: (partialEvent: __IHiveEvent) => void
  getLoginLink: () => string
  triggerHiveEvent: (
    event: __IHiveEvent,
    eventPath: HiveEventPaths,
    successCallback?: (value: string | number | object | boolean, data?: object) => void,
    failureCallback?: (value: any) => void,
  ) => void
}

export default useEvents
