import React, { FunctionComponent, ReactElement } from 'react'
import styles from './MessageWrapper.module.scss'
import LogoPoweredBy from 'components/LogoPoweredBy'
import LanguageEnSpToggle from 'components/LanguageEnSpToggle'
import { useLocalize } from 'localize'
import classNames from 'classnames'
import ExternalLink from 'components/ExternalLink'
import { testFeatureActive } from 'features'
import MultiLanguageSelector from 'components/MultiLanguageSelector'

interface Props {
  title?: string | ReactElement | ReactElement[]
  titleId?: string
  otherInfo?: string | ReactElement | ReactElement[]
  children?: React.ReactElement | React.ReactElement[] | string
  icon?: React.ReactElement
  showLogo?: boolean
  brandingLogo?: string | undefined
  brandingLogoAltText?: string | undefined
}

const MessageWrapper: FunctionComponent<Props> = ({
  children,
  title,
  titleId,
  otherInfo,
  icon,
  brandingLogo,
  brandingLogoAltText,
  showLogo = false,
}) => {
  const brandingLogoToUse = brandingLogo || ''
  const brandingLogoAltTextToUse = brandingLogoAltText || ''
  const { Localize, localization, changeLocalization } = useLocalize()
  const showMultiLanguageSwitcher = testFeatureActive('showMultiLanguageSwitcher')

  return (
    <main id='main' className='main-grad' tabIndex={-1}>
      <div className={styles.content}>
        <div className={styles.bannerTop}>
          {brandingLogoToUse ? (
            <div className={styles.contentBranding}>
              <img
                className={styles.contentBrandingImg}
                src={brandingLogoToUse}
                alt={brandingLogoAltTextToUse}
              />
            </div>
          ) : (
            <div></div>
          )}
          {localization && (
            <div className={styles.languageToggle}>
              {showMultiLanguageSwitcher ? (
                <MultiLanguageSelector
                  id='hw-message-language-selector'
                  selectedLocalization={localization as __Localization}
                  onLanguageSelect={changeLocalization}
                />
              ) : (
                <LanguageEnSpToggle
                  localization={localization}
                  onLanguageSelect={changeLocalization}
                  labelPrefix={Localize(
                    'SwitchLanguagePage',
                    localization === 'en-us' ? 'lang:es' : 'lang:en',
                  )}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.contentTop}>
          {icon && (
            <div aria-hidden className={styles.heroIconWrapper}>
              {icon}
            </div>
          )}
          {title && (
            <h1 id={titleId} className={styles.title}>
              {title}
            </h1>
          )}
          {otherInfo && <p className={styles.otherInfo}>{otherInfo}</p>}
          {children}
        </div>
        <div className={classNames(styles.logoWrapper, { [styles.showLogo]: showLogo })}>
          <ExternalLink className={styles.logoLink} href={Localize('AboutHealthwiseUrl')} hideIcon>
            <LogoPoweredBy theme='dark' />
          </ExternalLink>
        </div>
      </div>
    </main>
  )
}

export default MessageWrapper
