import classNames from 'classnames'
import React, { FunctionComponent, useState, useEffect, ReactElement } from 'react'
import styles from './LargeTextTokenizer.module.scss'

interface Props {
  children?: string
  className?: string
  breakOn?: number | string | 'half'
  forceBreak?: boolean
}

const LargeTextTokenizer: FunctionComponent<Props> = ({
  className,
  children = '',
  breakOn = 0,
  forceBreak,
}) => {
  const [tokens, setTokens] = useState<ReactElement[]>([])

  useEffect(() => {
    if (typeof breakOn === 'number' || breakOn === 'half') {
      const textParts = children.split(' ')
      const whereToBreak = breakOn === 'half' ? Math.ceil(textParts.length / 2) : breakOn

      if (textParts.length > whereToBreak) {
        const newTokens: ReactElement[] = []
        let toAdd: string[] = []

        for (let tpx = 0; tpx < textParts.length; tpx++) {
          if (tpx % whereToBreak === 0 && toAdd.length > 0) {
            newTokens.push(
              <span key={tpx}>
                <span className={classNames('hw-tokenized-text-token', styles.token)}>
                  {toAdd.join(' ')}
                </span>{' '}
              </span>,
            )
            toAdd = []
          }

          toAdd.push(textParts[tpx] + ' ')

          if (tpx === textParts.length - 1 && toAdd.length > 0) {
            newTokens.push(
              <span key={tpx}>
                <span
                  className={classNames('hw-tokenized-text-token', styles.token, {
                    [styles.blockToken]: forceBreak,
                  })}
                >
                  {toAdd.join(' ')}
                </span>{' '}
              </span>,
            )
          }
        }
        setTokens(newTokens)
      } else {
        setTokens([<>{children}</>])
      }
    } else if (typeof breakOn === 'string' && children.includes(breakOn)) {
      const textParts = children.split(breakOn)
      const newTokens: ReactElement[] = textParts.map((testPart, tpx) => {
        const appendText = tpx < textParts.length - 1 ? breakOn : ''
        return (
          <span
            key={tpx}
            className={classNames('hw-tokenized-text-token', styles.token, {
              [styles.blockToken]: forceBreak,
            })}
          >
            {testPart + appendText}
          </span>
        )
      })
      setTokens(newTokens)
    } else {
      setTokens([<>{children}</>])
    }
  }, [children, breakOn])

  return <span className={classNames(className, 'hw-tokenized-text', styles.tokens)}>{tokens}</span>
}

export default LargeTextTokenizer
