import React, { FunctionComponent, useMemo } from 'react'
import classNames from 'classnames'
import OptionsSelector, { SelectorOptionType } from 'components/OptionsSelector'
import { latestDisplayLocalizations } from 'config'
import { Language } from 'components/Icons'
import { useLocalize } from 'localize'

interface Props {
  className?: string
  id: string
  label?: string
  selectedLocalization: __Localization
  onLanguageSelect?: (language: __Localization) => void
}

const MultiLanguageSelector: FunctionComponent<Props> = ({
  className,
  id,
  label,
  selectedLocalization,
  onLanguageSelect,
}) => {
  const { Localize, getLocalizationName } = useLocalize()
  const languageOptions = useMemo<SelectorOptionType[]>(() => {
    const langOptions: SelectorOptionType[] = latestDisplayLocalizations.map(displayLoc => {
      const langInfo = getLocalizationName(displayLoc)
      if (langInfo?.nativeName && langInfo.localization) {
        return {
          label: langInfo.nativeName,
          value: langInfo.localization,
          brief: langInfo.short,
          defaultChecked: selectedLocalization === langInfo.localization,
        }
      }
      return { label: '', value: '' }
    })
    return langOptions
  }, [selectedLocalization])

  return (
    <div className={classNames(className)}>
      <OptionsSelector
        id={id}
        label={label}
        icon={<Language />}
        options={languageOptions}
        aria-label={Localize('SwitchLanguagePage')}
        title={Localize('SwitchLanguagePage')}
        onChange={value => {
          onLanguageSelect?.(value as __Localization)
        }}
      />
    </div>
  )
}

export default MultiLanguageSelector
