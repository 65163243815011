import { defaultOrganizationName, getLangFromLoc } from 'config'
import { debugErrorLogger } from 'common'
import { fetchCommunicationsOptions, postLoginCode, fetchCommunicationStatus } from 'apis'

export interface IndexLoginStateType {
  launchEventTriggered?: boolean
  loading?: boolean
  ready?: boolean
  comStatusSet?: boolean
  skipMessageOpen?: boolean
  errorMessage?: string
  errorCode?: number
  shouldTryRedirect?: boolean
  comOptions?: __CommunicationOptions
  authMethod?: AuthType
}

export type LoginResponseType = string | 'pending'

export const emailInputId = 'hw-index-email-address-input'
export const phoneInputId = 'hw-index-phone-address-input'
export type AuthType = 'sms' | 'email' | undefined

export const initialState: IndexLoginStateType = {
  launchEventTriggered: false,
  loading: false,
  ready: false,
  comStatusSet: false,
  skipMessageOpen: false,
  errorMessage: '',
  comOptions: {
    patientId: '',
    hasEmail: false,
    hasPhoneNumber: false,
    maskedEmail: '',
    maskedPhoneNumber: '',
  },
  authMethod: undefined,
}

export const handleComMethodChange = (
  state: IndexLoginStateType,
  elementId: string | undefined,
): IndexLoginStateType => {
  const newState: IndexLoginStateType = { ready: state.ready }
  if (elementId === emailInputId) {
    newState.authMethod = 'email'
    newState.ready = true
  } else if (elementId === phoneInputId) {
    newState.authMethod = 'sms'
    newState.ready = true
  }
  return newState
}

export const getLoginResponse = async (action: {
  patientId: string
  localization: __Localization
  comType: AuthType
  organizationId: string,
  subscriptionId: string
}): Promise<LoginResponseType> => {
  const response = await postLoginCode(
    action.patientId,
    action.comType as string,
    defaultOrganizationName,
    getLangFromLoc(action.localization),
    action.organizationId,
    action.subscriptionId
  )

  return response
}

export const getCommStatus = async (state: IndexLoginStateType): Promise<IndexLoginStateType> => {
  const newState: IndexLoginStateType = { comOptions: Object.assign({}, state.comOptions) }
  const response = await fetchCommunicationStatus()
  if (response.error) {
    debugErrorLogger(
      `Error checking twillio and sendgrid status: ${response.error.error}, ${response.error.status}`,
    )
  } else {
    newState.comStatusSet = true
    const newComOptions = Object.assign({}, state.comOptions)
    if (!response.sendGridIsOperational) {
      newComOptions.hasEmail = false
    }
    if (!response.twilioIsOperational) {
      newComOptions.hasPhoneNumber = false
    }
    newState.comOptions = newComOptions
  }
  return newState
}

export const getCommunicationsOptions = async (
  state: IndexLoginStateType,
  action: { localPatientId: string; organizationId: string; subscriptionId: string; comDownErrorMessage: string; comCodeErrorMessage: string },
): Promise<IndexLoginStateType> => {
  const newState: IndexLoginStateType = Object.assign({ shouldTryRedirect: false }, state)
  if (action.localPatientId && !state.comStatusSet) {
    const response = await fetchCommunicationsOptions(action.localPatientId, action.organizationId, action.subscriptionId)
    if (response.error) {
      newState.errorCode = response.error.status
      if (response.error.status && response.error.status >= 500) {
        debugErrorLogger('Com Options')
        debugErrorLogger(response)
        newState.errorMessage = action.comCodeErrorMessage
        newState.shouldTryRedirect = true
      } else if (response.error.status && response.error.status >= 400) {
        newState.errorMessage = action.comDownErrorMessage
      } else {
        newState.errorMessage = action.comCodeErrorMessage
      }
      if (response.error.error) {
        debugErrorLogger(response.error.error, response.error.status)
      }
    } else {
      newState.comOptions = response
      newState.comStatusSet = true
    }
  }
  return newState
}
