import React, { FunctionComponent, ReactNode, useMemo } from 'react'
import classNames from 'classnames'
import styles from './AlertMessage.module.scss'
import { useLocalize } from 'localize'
import { IconX, Info, Success, Error, Warning } from 'components/Icons'

export enum alertMessageType {
  success = 'success',
  warning = 'warning',
  info = 'info',
  error = 'error',
  popup = 'popup',
}

interface Props {
  messageType?: alertMessageType
  hideIcon?: boolean
  transparent?: boolean
  children?: ReactNode[] | ReactNode | string | undefined
  className?: string
  closeDescribedById?: string
  onClose?: () => void
}

const AlertMessage: FunctionComponent<Props> = ({
  messageType,
  className,
  children,
  hideIcon,
  transparent,
  closeDescribedById,
  onClose,
}) => {
  const { Localize } = useLocalize()
  const showCloseButton = useMemo<boolean>(() => {
    return typeof onClose === 'function'
  }, [onClose])

  const getIcon = () => {
    if (!hideIcon) {
      switch (messageType) {
        case alertMessageType.success:
          return <Success />
        case alertMessageType.info:
          return <Info />
        case alertMessageType.warning:
          return <Warning />
        case alertMessageType.error:
          return <Error />
        default:
          return <></>
      }
      //
    }
    return <></>
  }


  return (
    <div
      className={classNames(
        'hw-alert-message',
        styles.alertMessage,
        className,
        { [styles.success]: messageType === alertMessageType.success },
        { [styles.info]: messageType === alertMessageType.info },
        { [styles.error]: messageType === alertMessageType.error },
        { [styles.warning]: messageType === alertMessageType.warning },
        { [styles.transparent]: transparent },
      )}
      role={messageType === alertMessageType.popup ? 'alert' : ''}
    >
      {messageType === alertMessageType.popup || hideIcon ? null : (
        <div className={styles.boxIcon}>{getIcon()}</div>
      )}
      <div
        className={classNames(styles.messageContent, 'hw-alert-message-content', {
          [styles.narrower]: showCloseButton,
        })}
      >
        {children}
      </div>
      {showCloseButton ? (
        <button
          data-testid='hw-alert-message-close'
          className={classNames(styles.closeButton, 'hw-alert-message-close')}
          aria-label={Localize('Dismiss')}
          aria-describedby={closeDescribedById}
          title={Localize('Dismiss')}
          onClick={() => {
            onClose?.()
          }}
        >
          <IconX />
        </button>
      ) : null}
    </div>
  )
}

export default AlertMessage
