import React, {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import classNames from 'classnames'
import styles from './OptionsSelector.module.scss'
import { useLocalize } from 'localize'
import { getNewDashlessGuid } from 'common'
import { ArrowDown } from 'components/Icons'

export interface SelectorOptionType {
  label: string
  value: string
  briefLabel?: string
  defaultChecked?: boolean
  langCode?: string
}

interface Props {
  options: SelectorOptionType[]
  label?: string | ReactNode | ReactNode[]
  className?: string
  title?: string
  icon?: ReactNode
  makeStackableOnMobile?: boolean
  useButton?: boolean
  ignoreDirty?: boolean
  onChange?: (value: string) => void
  id?: string
  displayType?: 'long' | 'short'
  'aria-label'?: string
}

const OptionsSelector: FunctionComponent<Props> = ({
  options,
  label,
  onChange,
  className,
  icon,
  useButton,
  makeStackableOnMobile = false,
  displayType = 'long',
  ignoreDirty = false,
  id = getNewDashlessGuid(),
  ...otherProps
}) => {
  const { Localize } = useLocalize()
  const [selectedValue, setSelectedValue] = useState<string>('')
  const [dirty, setDirty] = useState<boolean>(false)

  const hasIcon = useMemo<boolean>(() => {
    return !!icon
  }, [icon])

  const defaultCheckedValue = useMemo<string>(() => {
    const defaultValue = options.find(option => option.defaultChecked)
    return `${defaultValue?.value || ''}`
  }, [options])

  const showBtn = useMemo<boolean>(() => {
    return Boolean(useButton && typeof onChange === 'function')
  }, [useButton, onChange, selectedValue, defaultCheckedValue])

  useEffect(() => {
    if ((!dirty || ignoreDirty) && options?.length) {
      if (defaultCheckedValue) {
        setSelectedValue(defaultCheckedValue)
      }
    }
  }, [defaultCheckedValue, selectedValue])

  return (
    <>
      {label && (
        <label className={styles.selectLabel} htmlFor={`${id}-standard-select`}>
          {label}
        </label>
      )}
      <div
        className={classNames(styles.optionsSelectorOuter, {
          [styles.stackable]: makeStackableOnMobile,
        })}
      >
        <div id={id} className={classNames(styles.selectWrapper, className, 'hw-option-selector')}>
          {hasIcon && (
            <span className={classNames(styles.imageWrapper, styles.iconWrapper)} aria-hidden>
              {icon}
            </span>
          )}
          <span className={classNames(styles.imageWrapper, styles.arrowWrapper)} aria-hidden>
            <ArrowDown />
          </span>
          <select
            className={classNames(styles.select, { [styles.withIcon]: hasIcon })}
            id={`${id}-standard-select`}
            data-testid={`${id}-selector`}
            onChange={(e: ChangeEvent) => {
              setDirty(true)
              const $target = e.target as HTMLInputElement
              setSelectedValue($target.value)
              if (!useButton && typeof onChange === 'function') {
                onChange($target.value)
              }
            }}
            value={selectedValue}
            aria-label={otherProps['aria-label']}
          >
            {options.map((option, ox) => {
              return (
                <option
                  className={styles.option}
                  data-testid={`${id}-option-${option.value}`}
                  value={`${option.value}`}
                  key={ox}
                  lang={option.langCode}
                >
                  {displayType === 'long' || !option.briefLabel ? option.label : option.briefLabel}
                </option>
              )
            })}
          </select>
        </div>
        {showBtn && selectedValue !== defaultCheckedValue ? (
          <button
            className={classNames(styles.goBtn, 'hw-btn rounded small')}
            type='button'
            data-testid={`${id}-option-selector-btn`}
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
              e.preventDefault()
              onChange?.(selectedValue)
            }}
            aria-describedby={`${id}-standard-select`}
            disabled={selectedValue === defaultCheckedValue}
          >
            {Localize('Set')}
            {otherProps['aria-label'] ? (
              <span className='offscreen-text'>{otherProps['aria-label']}</span>
            ) : null}
          </button>
        ) : null}
      </div>
    </>
  )
}

export default OptionsSelector
