import React, { FunctionComponent, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './AlertMessageContents.module.scss'

interface AlertMessageProps {
  title: string
  headingLevel?: 2 | 3 | 4 | 5
  children?: ReactNode[] | ReactNode | string | undefined
}

const AlertMessageContents: FunctionComponent<AlertMessageProps> = ({
  title,
  children,
  headingLevel,
}) => {
  const getHeading = () => {
    switch (headingLevel) {
      case 2:
        return <h2 className={styles.contentsTitle}>{title}</h2>
      case 3:
        return <h3 className={styles.contentsTitle}>{title}</h3>
      case 4:
        return <h4 className={styles.contentsTitle}>{title}</h4>
      case 5:
        return <h5 className={styles.contentsTitle}>{title}</h5>
      default:
        return <span className={styles.contentsTitle}>{title}</span>
    }
  }

  return (
    <div className={classNames(styles.contentsWrapper)}>
      {getHeading()}
      <div className={styles.contents}>{children ? children : null}</div>
    </div>
  )
}

export default AlertMessageContents
