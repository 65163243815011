import React, { ReactElement, FC, SyntheticEvent } from 'react'
import classNames from 'classnames'
import styles from './WaitButton.module.scss'
import { useLocalize } from 'localize'

interface BaseProps {
  children: ReactElement | string | (ReactElement | string)[]
  disableAnimation: boolean | undefined
  className?: string | undefined
  disabled?: boolean
  waiting?: boolean
  notReady?: boolean
  type?: 'submit' | 'button' | 'reset' | undefined
  onClick?: (e: SyntheticEvent) => void
}

// Require identifier for testing
interface IdProps extends BaseProps {
  /**
   * Optional: Unique identifier for testing purposes
   */
  'data-testid'?: string
  /**
   * Required: Unique identifier, may be used for testing purposes
   */
  id: string
}

interface TestProps extends BaseProps {
  /**
   * Required: Unique identifier for testing purposes
   */
  'data-testid': string
  /**
   * Optional: Unique identifier, may be used for testing purposes
   */
  id?: string
}

const WaitButton: FC<TestProps | IdProps> = ({
  children,
  className,
  disabled,
  waiting,
  notReady,
  disableAnimation,
  id,
  type = 'button',
  onClick,
  ...otherProps
}) => {
  const { Localize } = useLocalize()
  const classNameToUse = classNames(className, { [styles.not_ready]: notReady }, 'hw-btn')

  return (
    <button
      disabled={waiting || disabled}
      className={classNameToUse}
      id={id}
      data-testid={otherProps['data-testid']}
      onClick={onClick}
      type={type}
    >
      {waiting ? (
        <>
          <span role='alert' className='offscreen-text'>
            {Localize('Wait')}
          </span>
          <span className={styles.dots_wrapper}>
            <span
              className={classNames(`hw-loading`, `hw-loading-inline`, styles.inline_dots_light)}
              role='status'
            >
              <span
                className={classNames(styles.inline_dots, { [styles.animate]: !disableAnimation })}
                aria-busy
              >
                <span aria-hidden />
                <span aria-hidden />
                <span aria-hidden />
              </span>
              <span className={styles.inline_loading} aria-busy>
                {Localize('Wait')}
              </span>
            </span>
          </span>
        </>
      ) : (
        children
      )}
    </button>
  )
}

export default WaitButton
