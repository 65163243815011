import { useReducer, useEffect, FormEvent, useState, useRef, useMemo } from 'react'
import lodash from 'lodash'
import { NextPage } from 'next'
import Link from 'next/link'
import classNames from 'classnames'
import PageWrapper from 'components/PageWrapper'
import { useEvents } from 'backEndEvents'
import { useAppContext } from 'context/useAppContext'
import { useRouter } from 'next/router'
import styles from './login.module.scss'
import WaitButton from 'components/WaitButton'
import { MobilePhone, EmailAlternate, Lock } from 'components/Icons'
import { securityCodeLength, setAppSetting } from 'config'
import { useLocalize, AttachLocalizedList } from 'localize'
import locJson from './login.loc.json'
import MessageWrapper from 'components/MessageWrapper/MessageWrapper'
import LargeTextTokenizer from 'components/LargeTextTokenizer'
import { debugErrorLogger, debugLogger, getReducer } from 'common'
import {
  phoneInputId,
  emailInputId,
  initialState,
  handleComMethodChange,
  getLoginResponse,
  getCommStatus,
  getCommunicationsOptions,
  IndexLoginStateType,
} from 'pageServices/loginStateService'
import AlertMessage, { alertMessageType } from 'components/AlertMessage'
import AlertMessageContents from 'components/AlertMessage/AlertMessageContents'
import { setQueryStringFeatures } from 'features'
import AreaPlaceholder from 'components/AreaPlaceholder'
import { useBranding } from 'brandingContext/useBrandingContext'

AttachLocalizedList(locJson)

interface QueryParametersType {
  login__PageInfo?: string[] // slug, internal
  source?: string
  defaultMethod?: string
  orgId?: string
  subId?: string
}

interface SlugPartsType {
  patientId: string
  patientOrgId: string
  patientSubId: string
}

const Dashboard: NextPage = () => {
  const titleId = 'hw-login-form-title'
  const router = useRouter()

  const queryParams = useMemo(() => {
    const { login__PageInfo, source } = router.query
    const params: QueryParametersType = {
      login__PageInfo: login__PageInfo as string[],
      source: `${source || ''}`,
      defaultMethod: `${router.query.default || ''}`,
      orgId: `${router.query.orgId || ''}`,
      subId: `${router.query.subId || ''}`,
    }
    return params
  }, [router.query])

  const { updateEventData, triggerHiveEvent, currentEventVersions } = useEvents()
  const {
    setShouldReset,
    updateLoginInfo,
    verifyToken,
    loginRedirect,
    skipRedirect = '',
    setSkipLogin,
    userAppSettings,
    setCommunicationDown,
    setOrgId,
    setSubId,
    orgId,
    subId,
    assignmentId,
  } = useAppContext()
  const { Localize, localization, languageCode } = useLocalize()
  const { getBrandingProp } = useBranding()
  const [showLogoutMessage] = useState<boolean>(queryParams.source === 'logout')
  const [state, dispatch] = useReducer(getReducer<IndexLoginStateType>(), initialState)
  const skipMessageElement = useRef<HTMLDivElement>(null)

  const hasSkipRedirect = useMemo(() => {
    return Boolean(skipRedirect?.includes('/content/'))
  }, [skipRedirect])

  const hasLoginRedirect = useMemo(() => {
    return loginRedirect?.includes('/content/')
  }, [loginRedirect])

  const canCommunicate = useMemo(() => {
    return state.comOptions?.hasEmail || state.comOptions?.hasPhoneNumber
  }, [state.comOptions])

  const showSkipLink = useMemo(() => {
    return Boolean(hasSkipRedirect && skipRedirect && orgId && subId)
  }, [hasSkipRedirect, skipRedirect, orgId, subId])

  const comDownErrorMessage = useMemo(() => Localize('IndexCommunicationDownError'), [localization])
  const comCodeErrorMessage = useMemo(() => Localize('IndexInvalidCodeError'), [localization])

  const slugParts = useMemo(() => {
    const slug: string[] = queryParams.login__PageInfo || []
    const partsCandidate: SlugPartsType = {
      patientId: '',
      patientOrgId: '',
      patientSubId: '',
    }
    if (Array.isArray(slug) && slug.length >= 1) {
      partsCandidate.patientId = slug[0] || ''
      partsCandidate.patientOrgId = slug[1] || queryParams.orgId || ''
      partsCandidate.patientSubId = slug[2] || queryParams.subId || ''
    }
    return partsCandidate
  }, [queryParams])

  const setInstitutionIds = () => {
    if (slugParts?.patientOrgId && !orgId && setOrgId) {
      setOrgId(slugParts.patientOrgId)
    }
    if (slugParts?.patientSubId && !subId && setSubId) {
      setSubId(slugParts.patientSubId)
    }
  }

  // Initialize
  useEffect(() => {
    if (slugParts.patientId === 'undefined') {
      router.replace(`/error/missing`, undefined, { locale: languageCode })
    } else if (verifyToken?.(slugParts.patientId)) {
      if (orgId && subId) {
        const event: __ILaunchApplicationEvent = {
          eventAction: 'Verification',
          eventVersion: currentEventVersions['launchApplication'],
          healthwiseApplication: 'Education Module',
          healthwiseApplicationLocale: localization,
          organizationId: orgId,
          subscriptionId: subId,
          launchOrigin: 'Success',
        }
        triggerHiveEvent(event, 'launch/application')
      }
      router.replace(`/dashboard/${slugParts.patientId}`, undefined, { locale: languageCode })
    } else {
      setShouldReset?.(true)
    }
    setQueryStringFeatures()
    setSkipLogin?.(false)
    setCommunicationDown?.(false)
  }, [])

  useEffect(() => {
    if (triggerHiveEvent && !state.launchEventTriggered && slugParts.patientId) {
      setInstitutionIds()

      if (orgId && subId) {
        const event: __ILaunchApplicationEvent = {
          eventAction: 'Verification',
          eventVersion: currentEventVersions['launchApplication'],
          healthwiseApplication: 'Education Module',
          healthwiseApplicationLocale: localization,
          organizationId: orgId,
          subscriptionId: subId,
          launchOrigin: 'Load',
        }
        updateEventData({
          healthwiseApplicationLocale: localization,
          organizationId: orgId,
          subscriptionId: subId,
          userId: slugParts.patientId,
        })
        triggerHiveEvent(event, 'launch/application')
      }

      dispatch({ launchEventTriggered: true })
    }
  }, [])

  useEffect(() => {
    setInstitutionIds()
  }, [orgId, subId, slugParts?.patientOrgId, slugParts?.patientSubId])

  useEffect(() => {
    if (!state.comOptions?.hasEmail && state.comOptions?.hasPhoneNumber) {
      dispatch({ authMethod: 'sms', ready: true })
    } else if (state.comOptions?.hasEmail && !state.comOptions?.hasPhoneNumber) {
      dispatch({ authMethod: 'email', ready: true })
    }
  }, [state.comOptions?.hasEmail, state.comOptions?.hasPhoneNumber])

  useEffect(() => {
    const checkCommStatus = async () => {
      debugLogger('State at check com status', state)
      if (!state.comStatusSet) {
        const newState = await getCommStatus(state)
        dispatch(newState)
      }
    }
    if (!state.comStatusSet && state.comOptions?.patientId) {
      checkCommStatus()
    }
  }, [state.comOptions?.patientId, state.comStatusSet])

  useEffect(() => {
    const populateCommunicationsOptions = async () => {
      const newState = await getCommunicationsOptions(state, {
        localPatientId: slugParts.patientId,
        organizationId: slugParts.patientOrgId,
        subscriptionId: slugParts.patientSubId,
        comDownErrorMessage,
        comCodeErrorMessage,
      })
      if (!lodash.isEqual(state, newState)) {
        dispatch(newState)
      }
    }
    if (slugParts.patientId && !state.comStatusSet) {
      debugLogger('State at populate com options', state)
      populateCommunicationsOptions()
    }
  }, [slugParts.patientId, state.comStatusSet, state.comOptions])

  useEffect(() => {
    if (state.comStatusSet && queryParams.defaultMethod) {
      if (queryParams.defaultMethod === 'email') {
        dispatch({ ready: true, authMethod: 'email' })
      } else if (queryParams.defaultMethod === 'sms') {
        dispatch({ ready: true, authMethod: 'sms' })
      }
    }
  }, [queryParams.defaultMethod, state.comStatusSet])

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const $target = e.target as HTMLInputElement
    if ($target) {
      const newState = handleComMethodChange(state, $target.id)

      dispatch(newState)
    }
  }

  const handleLoginResponse = async () => {
    const comType = state.authMethod === 'sms' ? 'sms' : 'email'
    const response = await getLoginResponse({
      comType,
      patientId: slugParts.patientId,
      localization: localization as __Localization,
      organizationId: slugParts.patientOrgId,
      subscriptionId: slugParts.patientSubId,
    })

    if (response === 'too many requests') {
      dispatch({
        errorCode: 429,
        errorMessage: Localize('IndexCodeTooManyCodeAttempts'),
        loading: false,
      })
    } else if (response === 'pending') {
      dispatch({ ready: false, loading: false })
      router.replace(`/verify/${comType}`, undefined, { locale: languageCode })
    } else {
      debugErrorLogger(`Error getting one-time code`)
      dispatch({ errorMessage: Localize('IndexCodeCouldNotDeliver'), loading: false })
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (slugParts.patientId && canCommunicate) {
      const loginInfo: __CommunicationOptionsOptional = {
        hasEmail: state.comOptions?.hasEmail,
        hasPhoneNumber: state.comOptions?.hasPhoneNumber,
        maskedPhoneNumber: state.comOptions?.maskedPhoneNumber,
        maskedEmail: state.comOptions?.maskedEmail,
        patientId: slugParts.patientId,
      }
      updateLoginInfo?.(loginInfo)
      setAppSetting('patientId', slugParts.patientId)
      handleLoginResponse()
      dispatch({ loading: true })
    }

    return false
  }

  const commDown = useMemo(() => {
    return !canCommunicate && (state.comOptions?.maskedEmail || state.comOptions?.maskedPhoneNumber)
  }, [state.comOptions, canCommunicate])

  // Handle case where communications are down or communications endpoint is down
  useEffect(() => {
    if (state.comOptions?.patientId && commDown && hasLoginRedirect && loginRedirect) {
      router.push(loginRedirect, undefined, { locale: languageCode })
    }

    if (
      state.shouldTryRedirect &&
      hasLoginRedirect &&
      loginRedirect &&
      assignmentId &&
      loginRedirect.includes(assignmentId)
    ) {
      setCommunicationDown?.(true)
      router.push(loginRedirect.replace(assignmentId, '?header=false'), undefined, {
        locale: languageCode,
      })
    }
  }, [
    state.comOptions,
    state.shouldTryRedirect,
    commDown,
    hasLoginRedirect,
    loginRedirect,
    assignmentId,
  ])

  return (
    <>
      <PageWrapper title={Localize('IndexTitle')} hideLogo hideTimeoutWarning hideSkipLink>
        <MessageWrapper
          showLogo
          brandingLogo={getBrandingProp?.('login-logo')}
          brandingLogoAltText=''
        >
          <>
            {showLogoutMessage ? (
              <AlertMessage messageType={alertMessageType.success}>
                <AlertMessageContents title={Localize('IndexLoggedOutTitle')}>
                  {Localize('IndexLoggedOutMessage')}
                </AlertMessageContents>
              </AlertMessage>
            ) : null}
            <div className={styles.postLogoutMessageIcon}>
              <Lock theme='primary' />
            </div>

            <form onSubmit={handleSubmit}>
              <fieldset className={styles.comQuestion}>
                <legend id={titleId}>
                  <h1 className={styles.loginTitle}>
                    <LargeTextTokenizer breakOn={','}>{Localize('IndexTitle')}</LargeTextTokenizer>
                  </h1>
                  <h2 className={styles.loginOtherInfo}>
                    {Localize('IndexTitleMessage', securityCodeLength)}
                  </h2>
                </legend>
                {state.errorMessage ? (
                  <>
                    <div role='alert' className={styles.error}>
                      <AlertMessage messageType={alertMessageType.error}>
                        <AlertMessageContents
                          headingLevel={3}
                          title={
                            state.errorCode === 429
                              ? Localize('IndexCommunicationTooManyAttemptsErrorTitle')
                              : Localize('IndexCommunicationDownErrorTitle')
                          }
                        >
                          {state.errorMessage}
                        </AlertMessageContents>
                      </AlertMessage>
                    </div>
                    {state.shouldTryRedirect ? null : (
                      <div>
                        <button
                          data-testid='hw-com-failure-reload'
                          onClick={() => {
                            window.location.reload()
                          }}
                          className={classNames(styles.waitButton, 'hw-btn rounded')}
                        >
                          {Localize('IndexCommunicationDownTryAgain')}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {canCommunicate && !state.errorMessage ? (
                  <>
                    <div className={styles.choiceWrapper} role='radiogroup'>
                      {state.comOptions?.hasPhoneNumber && (
                        <label className={styles.comChoice}>
                          <span className={styles.radioWrapper}>
                            <input
                              className={classNames(styles.radio, 'hw-radio')}
                              type='radio'
                              name='comChoice'
                              id={phoneInputId}
                              value='sms'
                              onChange={handleChange}
                              checked={state.authMethod === 'sms'}
                            />
                          </span>
                          <span className={styles.iconWrapper}>
                            <span className='offscreen-text'>{Localize('IndexPhoneNumber')}</span>
                            <MobilePhone />
                          </span>
                          <span className={styles.comInfo}>
                            <span className={styles.comValue}>
                              {state.comOptions?.maskedPhoneNumber || ''}
                            </span>
                            <span className={styles.comInstructions}>
                              {Localize('IndexCodePhoneInstructions')}
                            </span>
                            {state.comOptions.patientHasNonFixedVoIP ? (
                              <span
                                className={styles.comVoipWarning}
                                data-testid='hw-voip-warning-message'
                              >
                                {Localize('IndexVoipPhoneWarning')}
                              </span>
                            ) : null}
                          </span>
                        </label>
                      )}
                      {state.comOptions?.hasEmail && (
                        <label
                          className={classNames(styles.comChoice, {
                            [styles.comChoiceBottom]: state.comOptions?.hasPhoneNumber,
                          })}
                        >
                          <span className={styles.radioWrapper}>
                            <input
                              required
                              className={classNames(styles.radio, 'hw-radio')}
                              type='radio'
                              name='comChoice'
                              id={emailInputId}
                              value='email'
                              onChange={handleChange}
                              checked={state.authMethod === 'email'}
                            />
                          </span>
                          <span className={styles.iconWrapper}>
                            <span className='offscreen-text'>{Localize('IndexEmailAddress')}</span>
                            <EmailAlternate />
                          </span>
                          <span className={styles.comInfo}>
                            <span className={styles.comValue}>{state.comOptions.maskedEmail}</span>
                            <span className={styles.comInstructions}>
                              {Localize('IndexCodeEmailInstructions')}
                            </span>
                          </span>
                        </label>
                      )}
                    </div>
                    <div className={styles.buttonWrapper}>
                      <WaitButton
                        className={classNames(styles.waitButton, 'rounded')}
                        waiting={state.loading}
                        type='submit'
                        data-testid='btn-index-send-email'
                        disableAnimation={userAppSettings?.disableAnimation}
                      >
                        {Localize('IndexSendCode')}
                      </WaitButton>
                    </div>
                  </>
                ) : null}
                {!canCommunicate && !state.errorMessage ? (
                  <AreaPlaceholder className={styles.choiceWrapperPlaceholder} />
                ) : null}
              </fieldset>
              {state.comOptions?.patientId &&
                !canCommunicate &&
                (commDown ? (
                  <>
                    <div className={styles.communicationWarning}>
                      <LargeTextTokenizer breakOn={7}>
                        {Localize('IndexCommunicationDownError')}
                      </LargeTextTokenizer>
                    </div>
                    <button
                      data-testid='hw-com-failure-reload'
                      onClick={() => {
                        window.location.reload()
                      }}
                      className={classNames(styles.waitButton, 'hw-btn rounded')}
                    >
                      {Localize('IndexCommunicationDownTryAgain')}
                    </button>
                  </>
                ) : (
                  <div className={styles.communicationWarning}>
                    <LargeTextTokenizer breakOn={8}>
                      {Localize('IndexCodeCommunicationError')}
                    </LargeTextTokenizer>
                  </div>
                ))}

              {showSkipLink && (
                <div className={styles.skipRedirectWrapper}>
                  <Link
                    href={skipRedirect}
                    locale={languageCode}
                    className={styles.skipRedirectLink}
                    onClick={e => {
                      e.preventDefault()
                      if (orgId && subId) {
                        const event: __ILaunchApplicationEvent = {
                          eventAction: 'Launch',
                          eventVersion: currentEventVersions['launchApplication'],
                          healthwiseApplication: 'Education Module',
                          healthwiseApplicationLocale: localization,
                          organizationId: orgId,
                          subscriptionId: subId,
                          launchOrigin: 'Skip',
                        }
                        triggerHiveEvent(event, 'launch/application')
                      }
                      setSkipLogin?.(true)
                      router.push(`${skipRedirect}`, undefined, {
                        locale: languageCode,
                      })
                      return false
                    }}
                    id='hw-skip-login-link'
                    data-testid='hw-skip-login-link'
                  >
                    {Localize('IndexCodeSkipLoginLinkText')}
                    <span className='offscreen-text'>{` ${Localize(
                      'IndexCodeDirectlyToContent',
                    )}`}</span>
                  </Link>
                  <button
                    type='button'
                    onClick={() => {
                      dispatch({ skipMessageOpen: !state.skipMessageOpen })
                      setTimeout(() => {
                        if (skipMessageElement.current) {
                          skipMessageElement.current.focus()
                        }
                      }, 50)
                    }}
                    aria-label={Localize('IndexWhatIsThis')}
                    className={classNames(styles.moreInfoBtn, {
                      [styles.active]: state.skipMessageOpen,
                    })}
                    aria-controls='hw-skip-login-help-message'
                    aria-expanded={state.skipMessageOpen}
                    aria-describedby='hw-skip-login-link'
                    data-testid='hw-skip-login-help-toggle'
                  >
                    <span className={styles.moreInfoBtnInner} aria-hidden>
                      ?
                    </span>
                  </button>
                  <div
                    tabIndex={-1}
                    ref={skipMessageElement}
                    id='hw-skip-login-help-message'
                    className={classNames(styles.moreInfoMessage, styles.moreInfoMessageTopRight, {
                      [styles.open]: state.skipMessageOpen,
                    })}
                  >
                    {Localize('IndexWhatIsThisMoreInfo')}
                  </div>
                </div>
              )}
            </form>
          </>
        </MessageWrapper>
      </PageWrapper>
    </>
  )
}

export default Dashboard
