import {
  EventSource,
  eventSourceValues,
  EventContentFormat,
  eventContentFormatValues,
  eventContentFormatHiveValues,
  EventVerification,
  eventVerificationValues,
} from './v2/backEndEventsTypes'

import StorageFactory, { getSessionStorage } from '../storageFactory'
import { getContentUrl } from 'common'
import { theUnPatientId } from 'config'

export const getContentFormatValue = (format: string | string[] | undefined): number => {
  let formatToUse
  if (Array.isArray(format)) {
    formatToUse = format.find(f => {
      let retValue = ''
      Object.keys(eventContentFormatValues).forEach(k => {
        const key: EventContentFormat = parseInt(k, 10)
        const eventContentFormatValue = eventContentFormatValues[key]
        if (!retValue && f === eventContentFormatValue) {
          retValue = eventContentFormatValue
        }
      })
      return f === retValue
    })
  } else {
    formatToUse = (format || '').toLowerCase()
  }

  switch (formatToUse) {
    case eventContentFormatValues[EventContentFormat.Video]:
      return EventContentFormat.Video
    case eventContentFormatValues[EventContentFormat.PDF]:
      return EventContentFormat.PDF
    default:
      return EventContentFormat.Text
  }
}

/**
 * Converts the enumerated content format value into the old content format values for non-hive events. Deprecated for new event types.
 * @deprecated
 * @param eventContentFormat
 * @returns
 */
export const getContentFormatParamValue = (
  eventContentFormat: EventContentFormat | number | undefined,
): string => {
  const testContentFormat = eventContentFormatValues[eventContentFormat as EventContentFormat]
  if (testContentFormat) {
    return testContentFormat
  }
  return eventContentFormatValues[EventContentFormat.Text]
}

/**
 * Converts the enumerated content format value into a hive-friendly content format value.
 * @param eventContentFormat
 * @returns
 */
export const getContentFormatParamHiveValue = (
  eventContentFormat: EventContentFormat | number | undefined,
): __EventFormat => {
  const testContentFormat = eventContentFormatHiveValues[eventContentFormat as EventContentFormat]
  if (testContentFormat) {
    return testContentFormat
  }
  return eventContentFormatHiveValues[EventContentFormat.Text]
}

/**
 * Converts the content source into a hive-friendly content source value.
 * @param eventContentSource
 * @returns
 */
export const getContentSourceParamHiveValue = (
  eventContentSource: string | undefined,
): __EventSource => {
  const testContentSource = eventContentSource as __EventSource
  if (testContentSource) {
    return testContentSource
  }
  return 'Healthwise' // TODO: Add value if content source is unknown?
}

export const getSourceValue = (source: string | string[] | undefined): string => {
  let sourceToUse
  if (Array.isArray(source)) {
    sourceToUse = source.find(s => {
      let retVal = ''
      Object.keys(eventSourceValues).forEach(k => {
        const eventSourceValue: string = eventSourceValues[k]
        if (!retVal && s === eventSourceValue) retVal = eventSourceValue
      })
      return s === retVal
    })
  } else {
    sourceToUse = (source || '').trim()
  }

  switch (sourceToUse) {
    case eventSourceValues[EventSource.Healthwise]:
      return EventSource.Healthwise
    case eventSourceValues[EventSource.InHouse]:
      return EventSource.InHouse
    case eventSourceValues[EventSource.CDC]:
      return EventSource.CDC
    case eventSourceValues[EventSource.FDB]:
      return EventSource.FDB
    case eventSourceValues[EventSource.NCI]:
      return EventSource.NCI
    case eventSourceValues[EventSource.CAM]:
      return EventSource.CAM
    default:
      return EventSource.Unknown
  }
}

export const getSourceParamValue = (eventSource: EventSource | number | undefined): string => {
  const testSource = eventSourceValues[eventSource as EventSource]
  if (testSource) {
    return testSource
  }
  return eventSourceValues[EventSource.Unknown]
}

export const getVerificationValue = (verification: string | string[] | undefined): number => {
  let verificationToUse
  if (Array.isArray(verification)) {
    verificationToUse = verification.find(v => {
      let retValue = ''
      Object.keys(eventVerificationValues).forEach(k => {
        const key: EventVerification = parseInt(k, 10)
        const eventVerificationValue = eventVerificationValues[key]
        if (!retValue && v === eventVerificationValue) {
          retValue = eventVerificationValue
        }
      })
      return v === retValue
    })
  } else {
    verificationToUse = (verification || '').toLowerCase()
  }

  let eventVerificationIndex: EventVerification = EventVerification.Success

  switch (verificationToUse) {
    case eventVerificationValues[EventVerification.Success]:
      eventVerificationIndex = EventVerification.Success
      break
    case eventVerificationValues[EventVerification.Skip]:
      eventVerificationIndex = EventVerification.Skip
      break
    case eventVerificationValues[EventVerification.Portal]:
      eventVerificationIndex = EventVerification.Portal
      break
  }

  return eventVerificationIndex
}

export const getVerificationParamValue = (
  eventVerification: EventVerification | number | undefined,
): string => {
  const testEventVerification = eventVerificationValues[eventVerification as EventVerification]
  if (testEventVerification) {
    return testEventVerification
  }
  return eventVerificationValues[EventVerification.Success]
}

export const eventSettings = new StorageFactory<'sessionId' | 'eventContentData'>(
  'event_data_settings',
  getSessionStorage(),
)

export const purgeEventSettings = (): void => {
  if (typeof window !== 'undefined') {
    eventSettings.purge()
  }
}

export const getLoginLinkFromEventData = (
  eventData: __IContentEvent,
  isContentOnlyLink = false,
  assignmentId = '',
  assignmentArtifact = '',
) => {
  if (eventData?.userId) {
    let {
      userId,
      contentIdentifier,
      contentLocale,
      organizationId,
      subscriptionId,
      contentFormat,
    } = eventData
    let linkUrl = `/${userId}`
    if (isContentOnlyLink && organizationId && subscriptionId) {
      const queryParams: __KeyValue = { orgId: organizationId, subId: subscriptionId }
      if (userId) {
        queryParams.patientId = userId
      }
      linkUrl = getContentUrl(contentIdentifier, contentLocale as __Localization, {}, queryParams)
    } else if (contentIdentifier && contentLocale && organizationId && subscriptionId) {
      const queryParams: { [key: string]: string } = {
        patientId: userId,
        orgId: organizationId,
        subId: subscriptionId,
      }

      if (contentFormat) {
        queryParams.format = contentFormat
      }
      linkUrl = getContentUrl(
        contentIdentifier,
        contentLocale as __Localization,
        { assignmentId, contentArtifact: assignmentArtifact },
        queryParams,
      )
    } else if (organizationId && subscriptionId) {
      linkUrl = `/${userId}/${organizationId}/${subscriptionId}`
    }
    return linkUrl
  }
  return ''
}

const invalidCharsRegex = /[^\w\-\.]/
export const sanitizeId = (idToSanitize = '') => {
  if (idToSanitize && idToSanitize !== theUnPatientId) {
    let tempId = idToSanitize.trim()
    const match = invalidCharsRegex.exec(tempId)
    if (match && match?.index > -1) {
      tempId = tempId.substring(0, match.index)
    }
    return tempId
  }
  return idToSanitize
}

export const sanitizeEventData = (newEventData: __IHiveEvent = {}): __IHiveEvent => {
  const sanitized = Object.assign({}, newEventData)
  sanitized.organizationId = sanitizeId(newEventData?.organizationId)
  sanitized.subscriptionId = sanitizeId(newEventData?.subscriptionId)
  sanitized.userId = sanitizeId(newEventData?.userId)
  return sanitized
}
